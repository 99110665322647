.gotolocation {
    bottom: 80px;
    right: 0.5em;
}
.gotolocation.ol-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s linear, visibility 0s linear .25s;
}
.ol-map {
    /*min-width: 600px;*/
    /*min-height: 500px;*/
    /*padding: 10px;*/
    /*height: 100vh;*/
    height: 100%;
    width: 100%;
}

.ol-control {
    position: absolute;
    background-color: rgba(255,255,255,0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-full-screen {
    top: .5em;
    right: .5em;
}