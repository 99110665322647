.MonthList-selected {
  background-color: rgba(25, 118, 210, 0.08) !important;
}

.MonthList-selected-first {
  border-top: 2px solid #0288D1 !important;
  background-color: rgba(25, 118, 210, 0.08) !important;
}

.MonthList-selected-last {
  border-bottom: 2px solid #0288D1 !important;
  background-color: rgba(25, 118, 210, 0.08) !important;
}